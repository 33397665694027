var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('BaseMasterFieldBlock',{attrs:{"title":"Обработчики","description":"Настройте обработчики, которые нужны для обработки этой активности клиента."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-skeleton-loader',{style:({height: '100%', width: '100%'}),attrs:{"loading":_vm.initAction,"type":"table-row@3"}},[(_vm.model.handlers && _vm.model.handlers.length)?_c('v-row',[_c('v-col',[_c('base-table',{attrs:{"class-name":"table-handlers","headers":_vm.headers,"items":_vm.handlers,"item-class":function () { return 'clickable-row'; },"is-custom-header":false,"word-operations":['активность', 'активности', 'активностей'],"options":{
                sortBy: ['id'],
              },"disable-pagination":"","hide-default-footer":"","search":_vm.search_comp},on:{"click:row":_vm.openHandlerFormClick},scopedSlots:_vm._u([{key:"item.actionData",fn:function(ref){
              var item = ref.item;
return [(item.isSegmentAction && item.action_json && item.action_json.segment_id)?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.$_.findWhere(_vm.pickSegments, {id: item.action_json.segment_id}))?_c('segment-chip',{staticClass:"segment-chip__action-data",attrs:{"max-width":"400px","name":_vm.$_.findWhere(_vm.pickSegments, {id: item.action_json.segment_id}).name,"color":_vm.$_.findWhere(_vm.pickSegments, {id: item.action_json.segment_id}).color,"is_public":_vm.$_.findWhere(_vm.pickSegments, {id: item.action_json.segment_id}).is_public}}):_vm._e()],1)],1):(item.isBonusAction && item.action_json && item.action_json.bonus_res_id && _vm.$_.findWhere(_vm.manualBonusRes, {id: item.action_json.bonus_res_id}))?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('segment-chip',{staticClass:"segment-chip__action-data",attrs:{"max-width":"400px","name":_vm.$_.findWhere(_vm.manualBonusRes, {id: item.action_json.bonus_res_id}).title + ': ' + {
                        MANUAL: item.action_json.value,
                        TO_MAX: 'До максимума',
                        TO_MIN: 'До нуля'
                      }[item.action_json.bunus_value_type||'MANUAL'],"color":"#EA971F"}})],1)],1):(item.isSaleBonusAction && item.action_json && item.action_json.bonus_res_id && _vm.$_.findWhere(_vm.buyBonusRes, {id: item.action_json.bonus_res_id}))?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('segment-chip',{staticClass:"segment-chip__action-data",attrs:{"max-width":"400px","name":_vm.$_.findWhere(_vm.buyBonusRes, {id: item.action_json.bonus_res_id}).title,"color":"#EA971F"}})],1)],1):(item.isNotificationAction && item.action_json && item.action_json.notification_id && _vm.$_.findWhere(_vm.notificationPickList, {id: item.action_json.notification_id}))?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('segment-chip',{staticClass:"segment-chip__action-data",attrs:{"max-width":"400px","name":_vm.$_.findWhere(_vm.notificationPickList, {id: item.action_json.notification_id}).name,"color":"#EA971F"}})],1)],1):_vm._e()]}},{key:"item.actionText",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"action__chips"},[_c('segment-chip',{staticClass:"chip__action-text",attrs:{"max-width":"400px","name":item.actionText,"text-color":"#fff","background-color":_vm.actionTypesColors[item.action_type]}}),_c('div',{staticClass:"chip__action-arrow",style:(("background: " + (_vm.hexToRgbA(_vm.actionTypesColors[item.action_type], '0.15'))))},[_c('v-icon',{attrs:{"color":_vm.actionTypesColors[item.action_type]}},[_vm._v(" $iconify_feather-arrow-right ")])],1)],1)]}},{key:"item.run_user",fn:function(ref){
                      var item = ref.item;
return [_c('user-column',{attrs:{"user":item.run_user||item.creater,"show-last-activity":false}})]}},{key:"item.active",fn:function(ref){
                      var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"custom-switch",attrs:{"loading":item.changeActiveAction,"disabled":item.changeActiveAction,"inset":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.activeChange(item, $event)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)],1)]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openHandlerFormClick(item)}}},[_vm._v(" mdi-chevron-right ")])]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"secondary","text":true,"ripple":false},on:{"click":_vm.createHandlerClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_plus-circle-outlined ")]),_vm._v(" Добавить обработчик ")],1)],1)],1)],1)]},proxy:true}])}),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[(!_vm.model.id)?_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"primary","loading":_vm.CreateBroadcasterAction},on:{"click":_vm.onSaveClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_ion-checkmark-circle-outline ")]),_c('span',[_vm._v("Создать активность")])],1):_vm._e()],1)],1),(_vm.editedHandler && _vm.showHandlerDialog)?_c('eventHandlerDialog',{attrs:{"model":_vm.editedHandler,"broadcaster":_vm.model},on:{"save":_vm.onSaveHandler,"delete":_vm.onDeleteHandler},model:{value:(_vm.showHandlerDialog),callback:function ($$v) {_vm.showHandlerDialog=$$v},expression:"showHandlerDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }