import Vue from 'vue'

export default class ProgramEventBroadcasterHandler {
    static ACTION_ENUM = Object.freeze({
        default: { id: null, text: '-' },
        BONUS_DEBIT: { id: 'BONUS_DEBIT', text: 'Списать бонусы' },
        BONUS_CREDIT: { id: 'BONUS_CREDIT', text: 'Начислить бонусы' },
        NOTIFICATION: { id: 'NOTIFICATION', text: 'Отправить рассылку' },
        SEGMENT_IN: { id: 'SEGMENT_IN', text: 'Добавить в сегмент' },
        SEGMENT_OUT: { id: 'SEGMENT_OUT', text: 'Исключить из сегмента' },
        SALE_BONUS_CREDIT: { id: 'SALE_BONUS_CREDIT', text: 'Начислить бонусы как процент от покупки' },
        LOG: { id: 'LOG', text: 'Логирование', disabled: false },

        find (id) {
            return this[id] || this.default
        },
        toList () {
            return [
                this.BONUS_DEBIT,
                this.BONUS_CREDIT,
                this.NOTIFICATION,
                this.SEGMENT_IN,
                this.SEGMENT_OUT,
                this.SALE_BONUS_CREDIT,
                this.LOG,
            ]
        },
    });

    constructor (data) {
        Object.assign(this, data)
        Vue.set(this, 'changeActiveAction', false)
    }

    get actionText () {
        return ProgramEventBroadcasterHandler.ACTION_ENUM.find(this.action_type)
            .text
    }

    get idText () {
        return this.isNew ? '-' : this.id
    }

    get isSegmentAction () {
        return this.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.SEGMENT_IN.id ||
        this.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.SEGMENT_OUT.id
    }

    get isBonusAction () {
        return this.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.BONUS_DEBIT.id ||
        this.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.BONUS_CREDIT.id
    }

    get isSaleBonusAction () {
        return this.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.SALE_BONUS_CREDIT.id
    }

    get isNotificationAction () {
        return this.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.NOTIFICATION.id
    }
}
