<template>
  <v-container>
    <BaseMasterFieldBlock
      title="Обработчики"
      description="Настройте обработчики, которые нужны для обработки этой активности клиента."
    >
      <template v-slot:input>
        <v-skeleton-loader
          :loading="initAction"
          :style="{height: '100%', width: '100%'}"
          type="table-row@3"
        >
          <v-row v-if="model.handlers && model.handlers.length">
            <v-col>
              <base-table
                class-name="table-handlers"
                :headers="headers"
                :items="handlers"
                :item-class="() => 'clickable-row'"
                :is-custom-header="false"
                :word-operations="['активность', 'активности', 'активностей']"
                :options="{
                  sortBy: ['id'],
                }"
                disable-pagination
                hide-default-footer
                :search="search_comp"
                @click:row="openHandlerFormClick"
              >
                <template v-slot:[`item.actionData`]="{ item }">
                  <v-row v-if="item.isSegmentAction && item.action_json && item.action_json.segment_id">
                    <v-col cols="auto">
                      <segment-chip
                        v-if="$_.findWhere(pickSegments, {id: item.action_json.segment_id})"
                        class="segment-chip__action-data"
                        max-width="400px"
                        :name="$_.findWhere(pickSegments, {id: item.action_json.segment_id}).name"
                        :color="$_.findWhere(pickSegments, {id: item.action_json.segment_id}).color"
                        :is_public="$_.findWhere(pickSegments, {id: item.action_json.segment_id}).is_public"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else-if="item.isBonusAction && item.action_json && item.action_json.bonus_res_id && $_.findWhere(manualBonusRes, {id: item.action_json.bonus_res_id})">
                    <v-col cols="auto">
                      <segment-chip
                        class="segment-chip__action-data"
                        max-width="400px"
                        :name="$_.findWhere(manualBonusRes, {id: item.action_json.bonus_res_id}).title + ': ' + {
                          MANUAL: item.action_json.value,
                          TO_MAX: 'До максимума',
                          TO_MIN: 'До нуля'
                        }[item.action_json.bunus_value_type||'MANUAL']"
                        color="#EA971F"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else-if="item.isSaleBonusAction && item.action_json && item.action_json.bonus_res_id && $_.findWhere(buyBonusRes, {id: item.action_json.bonus_res_id})">
                    <v-col cols="auto">
                      <segment-chip
                        class="segment-chip__action-data"
                        max-width="400px"
                        :name="$_.findWhere(buyBonusRes, {id: item.action_json.bonus_res_id}).title"
                        color="#EA971F"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else-if="item.isNotificationAction && item.action_json && item.action_json.notification_id && $_.findWhere(notificationPickList, {id: item.action_json.notification_id})">
                    <v-col cols="auto">
                      <segment-chip
                        class="segment-chip__action-data"
                        max-width="400px"
                        :name="$_.findWhere(notificationPickList, {id: item.action_json.notification_id}).name"
                        color="#EA971F"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.actionText`]="{ item }">
                  <div class="action__chips">
                    <segment-chip
                      class="chip__action-text"
                      max-width="400px"
                      :name="item.actionText"
                      text-color="#fff"
                      :background-color="actionTypesColors[item.action_type]"
                    />
                    <div
                      class="chip__action-arrow"
                      :style="`background: ${hexToRgbA(actionTypesColors[item.action_type], '0.15')}`"
                    >
                      <v-icon
                        :color="actionTypesColors[item.action_type]"
                      >
                        $iconify_feather-arrow-right
                      </v-icon>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.run_user`]="{ item }">
                  <user-column
                    :user="item.run_user||item.creater"
                    :show-last-activity="false"
                  />
                </template>
                <template v-slot:[`item.active`]="{ item }">
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-switch
                        v-model="item.active"
                        :loading="item.changeActiveAction"
                        :disabled="item.changeActiveAction"
                        inset
                        hide-details
                        class="custom-switch"
                        @click.stop=""
                        @change="activeChange(item, $event)"
                      />
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="openHandlerFormClick(item)">
                    mdi-chevron-right
                  </v-icon>
                </template>
                <!-- </v-data-table> -->
              </base-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="secondary"
                :text="true"
                :ripple="false"
                @click="createHandlerClick"
              >
                <v-icon left>
                  $iconify_plus-circle-outlined
                </v-icon>
                Добавить обработчик
              </v-btn>
            </v-col>
          </v-row>
        </v-skeleton-loader>
      </template>
    </BaseMasterFieldBlock>
    <v-row>
      <v-col />
    </v-row>
    <v-row>
      <v-col />
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          v-if="!model.id"
          :disabled="!valid"
          color="primary"
          :loading="CreateBroadcasterAction"
          @click="onSaveClick"
        >
          <v-icon left>
            $iconify_ion-checkmark-circle-outline
          </v-icon>
          <span>Создать активность</span>
        </v-btn>
      </v-col>
    </v-row>
    <eventHandlerDialog
      v-if="editedHandler && showHandlerDialog"
      v-model="showHandlerDialog"
      :model="editedHandler"
      :broadcaster="model"
      @save="onSaveHandler"
      @delete="onDeleteHandler"
    />
  </v-container>
</template>

<script>
  import Convertor from '@/mixins/convertor'
  import { mapActions, mapGetters } from 'vuex'
  import Vue from 'vue'
  import ProgramEventBroadcasterHandler from '@/models/program/broadcasterHandler'

  export default {
    components: {
      eventHandlerDialog: () => import('../components/eventHandlerDialog.vue'),
      SegmentChip: () => import('@/components/segment/SegmentChip'),
      UserColumn: () => import('@/components/colums/UserColumn.vue'),
    },
    mixins: [Convertor],
    model: {
      prop: 'model',
      event: 'change',
    },
    props: {
      model: {
        type: Object,
        required: true,
      },
      copyId: {
        type: Number,
        default: 0,
      }
    },
    data () {
      return {
        search: '',
        initAction: false,
        headers: [
          { text: 'ID', align: 'start', value: 'idText', width: '5em' },
          { text: 'Действие', value: 'actionText', width: '10em' },
          { text: 'Детали', value: 'actionData', filterable: false, sortable: false },
          { text: 'Ответственный', value: 'run_user', filterable: false, sortable: false },
          // { text: '', value: '', filterable: false, sortable: false }, //
          { text: 'Вкл/Выкл', value: 'active', align: 'center', width: '11em', filterable: false, sortable: false },
          { text: '', value: 'actions', width: '1em', filterable: false, sortable: false },

        ],
        editedHandler: null,
        showHandlerDialog: false,
        CreateBroadcasterAction: false,
        actionTypesColors: {
          BONUS_DEBIT: '#4FB82B',
          BONUS_CREDIT: '#4FB82B',
          SALE_BONUS_CREDIT: '#4FB82B',
          NOTIFICATION: '#709AB9',
          SEGMENT_IN: '#4FB82B',
          SEGMENT_OUT: '#ED1C24',
          LOG: '#709AB9',
        },
      }
    },
    computed: {
      ...mapGetters({
        pickSegments: 'crm/segment/pickSegments',
        manualBonusRes: 'company/bonus_resources/manualBonusRes',
        buyBonusRes: 'company/bonus_resources/buyBonusRes',
        notificationPickList: 'company/notifications/notificationPickList',
      }),
      search_comp () { return this.search ? this.search.trim().toLowerCase() : '' },
      valid () { return this.model.handlers && this.model.handlers.length },
      handlers () {
        return this.model.handlers.map((item) => {
          return new ProgramEventBroadcasterHandler(item)
        })
      },
    },
    async created () {
      try {
        this.initAction = true
        await this.getSegmentPickList(this.model.program_id)
        await this.getBonusResList(this.model.program_id)
        await this.getNotifyPickList(this.model.program_id)        
      } catch (error) {
        console.error(error)
      } finally {
        this.initAction = false
      }
    },
    methods: {
      ...mapActions({
        CreateBroadcasterHandler: 'company/event_broadcasters/CreateBroadcasterHandler',
        CreateBroadcaster: 'company/event_broadcasters/CreateBroadcaster',
        SetActiveBroadcasterHandler: 'company/event_broadcasters/SetActiveBroadcasterHandler',
        getSegmentPickList: 'crm/segment/getPickList',
        getBonusResList: 'company/bonus_resources/GetList',
        getNotifyPickList: 'company/notifications/getPickList',
      }),

      onNextClick () {
        if (this.$refs.form.validate()) {
          this.$emit('continue', true)
        }
      },

      openHandlerFormClick (item) {
        this.editedHandler = Object.copy(item)
        this.showHandlerDialog = true
      },
      async activeChange (item, active) {
        try {
          console.log('activeChange', item, active)
          item.changeActiveAction = true
          if (!item.isNew) {
            await this.SetActiveBroadcasterHandler({ handlerId: item.id, active })
          } else {
            item.active = active
          }
        } catch (error) {
          item.active = !item.active
        } finally {
          item.changeActiveAction = false
        }
      },
      createHandlerClick () {
        this.editedHandler = {
          // id:  this.$uuid()
          isNew: true,
          broadcaster_id: this.model.id,
          program_id: this.model.program_id,
          run_user_id: null,
          action_type: null,
          action_json: {},
          active: true,
        }

        this.showHandlerDialog = true
      },
      ADD_HANDLER (newHandler) {
        const indexHandler = this.model.handlers.findIndex((x) => x.id === newHandler.id)
        if (indexHandler >= 0) {
          const handler = this.model.handlers[indexHandler]
          Vue.set(this.model.handlers, indexHandler, Object.assign({}, handler, newHandler))
        } else {
          this.model.handlers.push(newHandler)
        }
      },

      REMOVE_HANDLER (handler) {
        const indexHandler = this.model.handlers.findIndex((x) => x.id === handler.id)
        if (indexHandler >= 0) this.model.handlers.splice(indexHandler, 1)
      },

      async onSaveClick () {
        try {
          if (this.model.client_filter.segment_id_list?.length > 0) {
            this.model.client_filter.segment_id_list = this.model.client_filter.segment_id_list.map(function (element) {
              if (typeof element == "object") { return element.id }
              else { return element }
            });
          }

          this.CreateBroadcasterAction = true
          const broadcaster = await this.CreateBroadcaster(this.model)

          this.$emit('continue', true)
          this.model.handlers.forEach(item => {
            const data = Object.assign({}, item, { name: this.copyId ? this.$uuid() : item.id, broadcaster_id: broadcaster.id })
            this.CreateBroadcasterHandler(data)
          })
        } catch (e) {
          console.log(e)
        } finally {
          this.CreateBroadcasterAction = false
        }
      },
      onSaveHandler (handler) {
        console.log('onSaveHandler', handler)
        handler.id = handler.id || this.$uuid()
        this.ADD_HANDLER(handler)
      },
      onDeleteHandler (handler) {
        this.REMOVE_HANDLER(handler)
      },
    },
  }
</script>
<style lang="scss" scoped>
@import '@/views/dashboard/form_component/_form-component.scss';

.action__chips {
  display: inline-flex;
  width: 100%;
  align-items: center;
  .chip__action-text {
    border-radius: 8px;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    height: 21px;
    & ::v-deep {
      span {
        margin-left: 6px;
      }
    }
  }

  .chip__action-arrow {
    margin-left: 19px;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    padding: 0!important;
    display: flex;
    justify-content: center;
  }
}

.segment-chip__action-data {
  align-items: center;
  font-size: 10.5px;
  padding: 1px 8px 1px 8px;
}

</style>
